import React from 'react';
import ReactTooltip from 'react-tooltip';
import IconInfoCircle from '../../assets/img/components/IconInfoCircle';

const getAHPString = ahp => {
  let AHPString = '';
  let firstChunk = null;
  let secondChunk = null;
  let AHPInt = ahp.toFixed(0);
  switch (AHPInt.length) {
    case 3:
      return `$${AHPInt}`;
    case 4:
      firstChunk = AHPInt.toString()
        .split('')
        .slice(0, 1)
        .join('');
      secondChunk = AHPInt.toString()
        .split('')
        .slice(1)
        .join('');
      AHPString = `$${firstChunk},${secondChunk}`;
      return AHPString;
    case 5:
      firstChunk = AHPInt.toString()
        .split('')
        .slice(0, 2)
        .join('');
      secondChunk = AHPInt.toString()
        .split('')
        .slice(2)
        .join('');
      AHPString = `$${firstChunk},${secondChunk}`;
      return AHPString;
    default:
      return AHPInt;
  }
};

export default ({ priceChanges, handleConfirmedPriceChanges }) => (
  <>
    <h4 className="price-changes-section-title">Medi-Share Pricing Notice</h4>
    <div>
      {!!priceChanges.length &&
        priceChanges.map(change => {
          const guidelinesURL =
            'https://www.medishare.com/medi-share/what-is-medishare/how-medi-share-works/';
          const guidelinesPath = change.program_name.includes('SA')
            ? 'senior-assist-guidelines'
            : 'medi-share-guidelines';
          return (
            <div key={change.program_name} className="price-changes-content">
              <div className="price-changes-info-text">
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    The change in address and/or marital status will adjust the
                    Monthly Share Amount.
                  </span>
                  <br />
                  See estimated pricing below.
                </p>
                <div
                  className="price-changes-info-text-tooltip-container"
                  data-for="monthly-share-tip"
                  data-tip="Monthly Share Amount is the monthly contribution based on age, marital status, how many are in the household, and location."
                >
                  <ReactTooltip
                    id="monthly-share-tip"
                    delayHide={4000}
                    effect="solid"
                    type="light"
                    className="price-changes-monthly-tooltip"
                    border="true"
                    borderColor="#dddbda"
                  />
                  <IconInfoCircle iconColor="#242a42" />
                </div>
              </div>
              <p>
                Note: Sharing in eligible maternity events is dependent on
                marital status. See{' '}
                <a href={guidelinesURL + guidelinesPath}>Guidelines</a>.
              </p>
              <div className="price-changes-info">
                <div className="price-changes-ahp-section">
                  <span>
                    {getAHPString(change.ahp_amount)} Annual Household Portion
                  </span>
                  <div
                    className="price-changes-ahp-tooltip-container"
                    data-for="ahp-tip"
                    data-tip="The annual amount a household is responsible for before eligible medical bills will be approved for sharing."
                  >
                    <ReactTooltip
                      id="ahp-tip"
                      delayHide={4000}
                      effect="solid"
                      type="light"
                      className="price-changes-ahp-tooltip"
                      border="true"
                      borderColor="#dddbda"
                    />
                    <IconInfoCircle iconColor="#fff" />
                  </div>
                </div>
                <div className="price-changes-breakdown-section">
                  <div className="price-changes-breakdown-info">
                    <span>Current Pricing</span>
                    <span className="price-changes-breakdown-amount">
                      ${change.old_price.toFixed(2)}
                    </span>
                    <span className="price-changes-breakdown-monthly">
                      Monthly Share
                    </span>
                  </div>
                  <div className="price-changes-breakdown-info price-changes-breakdown-line">
                    <span>Adjusted Pricing</span>
                    <span className="price-changes-breakdown-amount">
                      ${change.new_price.toFixed(2)}
                    </span>
                    <span className="price-changes-breakdown-monthly">
                      Monthly Share
                    </span>
                  </div>
                </div>
                <div className="price-changes-footer-section">
                  <span className="price-changes-footer-text">
                    *Pricing displayed does not include any adjustments,
                    discounts, late fees etc.
                  </span>
                </div>
              </div>
              <p>
                The Monthly Share Amount will be updated for the following
                month's billing cycle. For members with scheduled EFT payments,
                the following month's scheduled transfer will be automatically
                updated.
              </p>
              <p>
                You will receive notification by email with guidance to view and
                select additional Annual Household Portion options.
              </p>
              <button
                data-testid="price-changes-confirm-button"
                onClick={() => handleConfirmedPriceChanges()}
              >
                Done
              </button>
            </div>
          );
        })}
    </div>
  </>
);
