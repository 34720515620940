import { SHOW_MODAL, HIDE_MODAL } from '../redux-constants';

export function showModal(content) {
  return dispatch => {
    dispatch({
      type: SHOW_MODAL,
      content,
    });
  };
}

export function hideModal() {
  return dispatch => {
    dispatch({
      type: HIDE_MODAL,
    });
  };
}
