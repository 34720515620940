import Qs from 'qs';

export const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response.text().then(text => {
      if (text === '') return '';
      return JSON.parse(text);
    });
  } else if (response.status === 401) {
    // Logout
  }
  return response.json().then(res => {
    const err = new Error();
    err.response = res;
    throw err;
  });
};

export const generateQuery = query => {
  if (query === null || query === undefined) return '';
  if (typeof query !== 'object') return `?${query}`;
  return `?${Qs.stringify(query)}`;
};

export const headers = token => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
});

const BaseAPI = {
  get: (url, id, query, token) => {
    if (id) url += `/${id}`;
    if (query) url += `${generateQuery(query)}`;
    return fetch(url, {
      method: 'get',
      headers: headers(token),
    })
      .then(response => checkStatus(response))
      .then(data => data);
  },

  edit: (url, id, payload, token) =>
    fetch(id === null ? url : `${url}/${id}`, {
      method: 'put',
      headers: headers(token),
      body: JSON.stringify(payload),
    }).then(checkStatus),

  create: (url, payload, token) =>
    fetch(url, {
      method: 'post',
      headers: headers(token),
      body: JSON.stringify(payload),
    }).then(checkStatus),

  delete: (url, id, token) =>
    fetch(id === null ? url : `${url}/${id}`, {
      method: 'delete',
      headers: headers(token),
    }).then(checkStatus),
};

export default BaseAPI;
