import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import DocumentTitle from '../DocumentTitle';
import slugify from '../../lib/slugify';
import './styles.css';

const Page = ({ children, title, style = {} }) => (
  <DocumentTitle title={title} style={style}>
    <Container className={`page ${slugify(title)}`} style={style}>
      {children}
    </Container>
  </DocumentTitle>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default Page;
