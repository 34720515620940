import React from 'react';

export default props => {
  const iconColor = props.iconColor || '#3c3c3c';
  return (
    <svg width="16px" height="17px" viewBox="0 0 16 17">
      <path
        fill={iconColor}
        d="M10.3057692,7.93653846 L15.8057692,2.39423077 C16.0596154,2.14038462 16.0596154,1.75961538 15.8057692,1.50576923 L14.9596154,0.617307692 C14.7057692,0.363461538 14.325,0.363461538 14.0711538,0.617307692 L8.52884615,6.15961538 C8.35961538,6.32884615 8.10576923,6.32884615 7.93653846,6.15961538 L2.39423077,0.575 C2.14038462,0.321153846 1.75961538,0.321153846 1.50576923,0.575 L0.617307692,1.46346154 C0.363461538,1.71730769 0.363461538,2.09807692 0.617307692,2.35192308 L6.15961538,7.89423077 C6.32884615,8.06346154 6.32884615,8.31730769 6.15961538,8.48653846 L0.575,14.0711538 C0.321153846,14.325 0.321153846,14.7057692 0.575,14.9596154 L1.46346154,15.8480769 C1.71730769,16.1019231 2.09807692,16.1019231 2.35192308,15.8480769 L7.89423077,10.3057692 C8.06346154,10.1365385 8.31730769,10.1365385 8.48653846,10.3057692 L14.0288462,15.8480769 C14.2826923,16.1019231 14.6634615,16.1019231 14.9173077,15.8480769 L15.8057692,14.9596154 C16.0596154,14.7057692 16.0596154,14.325 15.8057692,14.0711538 L10.3057692,8.52884615 C10.1365385,8.35961538 10.1365385,8.10576923 10.3057692,7.93653846 L10.3057692,7.93653846 Z"
        id="path-1"
      />
    </svg>
  );
};
