/* Edit Profile */
export const SET_LOADING = 'SET_LOADING';
export const SET_AUTH_DATA = 'SET_AUTH_DATA';
export const AUTH_ERROR = 'AUTH_ERROR';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const INVALIDATE_TOKEN = 'INVALIDATE_TOKEN';
export const INVALIDATE_TOKEN_ERROR = 'INVALIDATE_TOKEN_ERROR';

/** Contact Info **/
export const UPDATE_CONTACT_INFO_FIELD = 'UPDATE_CONTACT_INFO_FIELD';
export const TOGGLE_MAILING_SAME_AS_BILLING = 'TOGGLE_MAILING_SAME_AS_BILLING';

export const ON_SAVE_CONTACT_INFO_REQUEST = 'ON_SAVE_CONTACT_INFO_REQUEST';
export const ON_SAVE_CONTACT_INFO_SUCCESS = 'ON_SAVE_CONTACT_INFO_SUCCESS';
export const ON_SAVE_CONTACT_INFO_FAILURE = 'ON_SAVE_CONTACT_INFO_FAILURE';

export const TOGGLE_DISPLAY_MESSAGE = 'TOGGLE_DISPLAY_MESSAGE';

export const TOGGLE_DISPLAY_PRICE_CHANGES = 'TOGGLE_DISPLAY_PRICE_CHANGES';
export const SET_PRICE_CHANGES = 'SET_PRICE_CHANGES';

/** Set Password **/
export const GET_PASSWORD_REQUIREMENTS = 'GET_PASSWORD_REQUIREMENTS';
export const SET_DEFAULT_PASSWORD_REQUIREMENTS =
  'SET_DEFAULT_PASSWORD_REQUIREMENTS';
export const SET_PASSWORD_AUTHENTICATE_TOKEN =
  'SET_PASSWORD_AUTHENTICATE_TOKEN';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_ERROR_MESSAGE = 'SET_PASSWORD_ERROR_MESSAGE';
export const SET_PASSWORD_LOADING = 'SET_PASSWORD_LOADING';
export const CLEAR_PASSWORD_ERROR_MESSAGE = 'CLEAR_PASSWORD_ERROR_MESSAGE';
export const UPDATE_NEW_PASSWORD_VALIDITY = 'UPDATE_NEW_PASSWORD_VALIDITY';

/** Window **/
export const UPDATE_VIEW = 'UPDATE_VIEW';

/** HIPAA **/
export const HIPAA_SET_DATA = 'HIPAA_SET_DATA';
export const HIPAA_ADD_AUTHORIZED_USER = 'HIPAA_ADD_AUTHORIZED_USER';
export const HIPAA_REMOVE_AUTHORIZED_USER = 'HIPAA_REMOVE_AUTHORIZED_USER';

/** Modal **/
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

/** Paperless Statements **/
export const PAPERLESS_SET_DATA = 'PAPERLESS_SET_DATA';
export const PAPERLESS_EDIT_DATA = 'PAPERLESS_EDIT_DATA';

/** Message Methods **/
export const SET_MESSAGE_METHODS = 'SET_MESSAGE_METHODS';
export const EDIT_MESSAGE_METHOD = 'EDIT_MESSAGE_METHOD';
