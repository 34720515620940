import React from 'react';
import PropTypes from 'prop-types';

const Body = ({ children }) => (
  <div id="content" className="body">
    {children}
  </div>
);

Body.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Body;
