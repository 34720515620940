import React from 'react';

import Page from '../Page';

const NotFound = () => (
  <Page title="Not Found">
    <p>Sorry, the requested page does not exist.</p>
  </Page>
);

export default NotFound;
