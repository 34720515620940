import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  authenticateUser,
  handleConfirmPriceChanges,
  onSaveContactInfo,
  toggleDisplayMessage,
} from '../../actions/profile';
import { usePrevious } from '../../hooks/use-previous';
import { useQuery } from '../../hooks/use-query';
import Page from '../Page';
import EditProfilePage from './EditProfilePage';
import PriceChangesPage from './PriceChangesPage';
import ProfileMessageBar from './ProfileMessageBar';
import './styles.css';

const AccountSettings = () => {
  const [expanded, setExpanded] = useState(null);
  const query = useQuery();
  const dispatch = useDispatch();
  const {
    isLoading,
    error,
    auth,
    contactInfo,
    priceChanges,
    displaySaved,
    displayError,
  } = useSelector(state => state.profile);
  const prevDisplaySaved = usePrevious(displaySaved);
  const prevDisplayError = usePrevious(displayError);

  useEffect(() => {
    const jwt = query.get('jwt');
    dispatch(authenticateUser(jwt));
  }, []);

  useEffect(() => {
    if (
      (!prevDisplaySaved && displaySaved) ||
      (!prevDisplayError && displayError)
    ) {
      window.scrollTo(0, 0);
    }
  }, [displaySaved, displayError]);

  function toggleSectionExpansion(sectionName) {
    setExpanded(sectionName === expanded ? null : sectionName);
  }

  async function handleSaveContactInfo() {
    try {
      window.scrollTo(0, 0);
      const priceChanges = await dispatch(onSaveContactInfo(contactInfo));
      if (!priceChanges) {
        redirectAfterSave();
      }
    } catch (err) {
      console.error(err);
    }
  }

  function handleConfirmedPriceChanges() {
    try {
      dispatch(handleConfirmPriceChanges());
      redirectAfterSave();
    } catch (err) {
      console.error(err);
    }
  }

  function redirectAfterSave() {
    const redirectOnDone = query.get('redirectOnDone');
    console.log(redirectOnDone);
    if (redirectOnDone) {
      const params = encodeURI(
        'toastType=success&toastHeading=Profile updated.&toastContent=Thank you for updating your information.'
      );
      const redirectUrl = `${redirectOnDone}?${params}`;
      window.location.assign(redirectUrl);
    }
  }

  const sectionProps = {
    expanded: expanded,
    toggleSectionExpansion: sectionName => toggleSectionExpansion(sectionName),
  };
  if (
    error &&
    error.response &&
    error.response.errors &&
    error.response.errors[0] &&
    error.response.errors[0].status === 401
  ) {
    return (
      <Page title="Error!">
        <h2>Whoops!</h2>
        <p>
          Something went wrong! The user was not found or your token has
          expired.
        </p>
        <p>
          Please login again{' '}
          <a href={`${process.env.REACT_APP_WSOL_URL}/login/`}>here</a>
        </p>
      </Page>
    );
  } else if (auth.token) {
    return (
      <Page id="edit-profile" title="Edit Profile">
        {displaySaved && (
          <ProfileMessageBar
            toggleDisplay={() =>
              dispatch(toggleDisplayMessage('displaySaved', false))
            }
            message="Your profile was saved successfully"
            bgColor="#78a12f"
            saved
          />
        )}
        {displayError && (
          <ProfileMessageBar
            toggleDisplay={() =>
              dispatch(toggleDisplayMessage('displayError', false))
            }
            message="Sorry, there was an error saving your profile."
            bgColor="red"
          />
        )}
        <h1>Edit Profile</h1>
        {!!priceChanges.displayPriceChanges ? (
          <PriceChangesPage
            priceChanges={priceChanges.priceChanges}
            handleConfirmedPriceChanges={handleConfirmedPriceChanges}
          />
        ) : (
          <EditProfilePage
            sectionProps={sectionProps}
            isLoading={isLoading}
            onSaveContactInfo={handleSaveContactInfo}
          />
        )}
      </Page>
    );
  } else {
    return <div />;
  }
};

AccountSettings.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default AccountSettings;
