import React, { Component } from 'react';

import Page from '../Page';
import Spinner from '../Spinner';
import BaseAPI from '../../utils/API/BaseAPI';
import Constants from '../../utils/API/Constants';
import Envelope from '../../assets/img/envelope.svg';
import './styles.css';

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      isLoading: false,
      success: false,
      error: false,
      maskedEmail: null,
    };
  }

  sendEmailVerification() {
    if (this.state.username) {
      this.setState({ isLoading: true });
      const data = { username: this.state.username };
      BaseAPI.create(`${Constants.API_PASSWORDS_URL}`, data, null)
        .then(res => {
          this.setState({
            maskedEmail: this.getMaskedEmail(res),
            success: true,
            isLoading: false,
          });
        })
        .catch(() =>
          this.setState({
            error: true,
            isLoading: false,
          })
        );
    }
  }

  getMaskedEmail(res) {
    if (res.partial_email) {
      // Server should return a premasked email associated with the username
      return res.partial_email;
    } else {
      // At this time, the server does not return a premasked email.
      const emailParts = this.state.username.split('@');
      if (emailParts.length === 2) {
        // User entered an email address so we can parse out the domain at least
        return `@${emailParts[1]}`;
      } else {
        return '';
      }
    }
  }

  render() {
    const contactUrl = `${
      process.env.REACT_APP_WSOL_URL
    }/our-ministry/contact-us/`;

    return (
      <Page title="Forgot Password">
        {this.state.success ? (
          <h2>We Sent You An Email!</h2>
        ) : (
          <h2>Verify Your Username</h2>
        )}
        {this.state.isLoading && <Spinner />}
        {!this.state.success && !this.state.isLoading && !this.state.error && (
          <div>
            <input
              name="username"
              type="text"
              value={this.state.username}
              placeholder="Username"
              onChange={({ target: { value: username } }) =>
                this.setState({ username })
              }
              autoFocus
            />
            <button onClick={() => this.sendEmailVerification()}>Submit</button>
          </div>
        )}
        {!this.state.isLoading && this.state.error && (
          <div>
            <p>An error occurred while requesting a password reset email.</p>
            <p>
              Please ensure that your username or email is correct and try
              again.
            </p>
            <button onClick={() => this.setState({ error: false })}>
              Re-enter Username
            </button>
          </div>
        )}
        {this.state.success && !this.state.isLoading && (
          <div>
            <div id="email-sent-box">
              <img src={Envelope} alt="Message sent" />
              <div>
                We have sent an email with complete instructions to the
                {' ' + this.state.maskedEmail} email address associated with
                your account.
              </div>
            </div>
            <p>
              Please remember to use this password to login to the Member
              Center.
            </p>
            <p>
              This email can take a few minutes to get to you. Also, please be
              sure to check your spam folder in case it found its way there. If
              you don’t receive this email within a few minutes, please contact
              Member Services <a href={contactUrl}>here</a>.
            </p>
          </div>
        )}
      </Page>
    );
  }
}
