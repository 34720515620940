import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Body from '../Body';
import Footer from '../Footer';
import Header from '../Header';
import Modal from '../Modal';
import AccountSettings from '../AccountSettings';
import ResetPassword from '../ResetPassword';
import NotFound from '../NotFound';
import ForgotPassword from '../ForgotPassword';

import './styles.css';

const App = () => {
  return (
    <div className="app">
      <Header />
      <Body>
        <Switch>
          <Route component={AccountSettings} exact path="/account/edit" />
          <Route component={ResetPassword} exact path="/login/reset-password" />
          <Route component={ResetPassword} exact path="/account/activate" />
          <Route
            component={ForgotPassword}
            exact
            path="/account/forgot-password"
          />
          <Route
            path="/groups/sets/:id"
            render={() => {
              window.location = `${process.env.REACT_APP_WSOL_URL}/mobileapp`;
            }}
          />
          <Route
            exact
            path="/pricing"
            render={() => {
              window.location = `https://mychristiancare.org/medi-share/what-is-medishare/share-calculator/`;
            }}
          />
          <Route component={NotFound} />
        </Switch>
      </Body>
      <Footer />
      <Modal />
    </div>
  );
};

export default App;
