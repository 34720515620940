import '@babel/polyfill';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';

import App from './components/App';
import ProviderAPI from './lib/ProviderAPI';
import store from './store';
import { updateView } from './actions/view';

ProviderAPI.setConfig({
  url: process.env.REACT_APP_PROVIDER_API_URL,
});

window.addEventListener('resize', ({ target: { innerWidth } }) =>
  store.dispatch(updateView({ innerWidth }))
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    for (let registration of registrations) {
      registration.unregister().then(() => {
        if (!localStorage.getItem('cacheReset')) {
          caches.keys().then(function(names) {
            for (let name of names) caches.delete(name);
          });
          localStorage.setItem('cacheReset', 'yes');
          window.location.reload(true);
        }
      });
    }
  });
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
