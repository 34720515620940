import React from 'react';
import IconCheckmark from '../../assets/img/components/IconCheckmark';
import IconClose from '../../assets/img/components/IconClose';

export default ({ toggleDisplay, message, saved, bgColor }) => (
  <div
    className="profile-message-container"
    style={{ backgroundColor: bgColor }}
  >
    <div className="profile-message-text">
      {saved && <IconCheckmark iconColor="#fff" />}
      <p>{message}</p>
    </div>
    <div onClick={() => toggleDisplay()}>
      <IconClose iconColor="#fff" />
    </div>
  </div>
);
