export const initialState = {
  query: '',
  isFetching: false,
  hasFailed: false,
  records: null,
};

const providers = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROVIDERS_QUERY':
      return {
        ...state,
        query: action.query,
      };

    case 'FETCH_PROVIDERS_REQUEST':
      return {
        ...state,
        isFetching: true,
        hasFailed: false,
        records: null,
      };

    case 'FETCH_PROVIDERS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        hasFailed: false,
        records: action.records,
      };

    case 'FETCH_PROVIDERS_FAILURE':
      return {
        ...state,
        isFetching: false,
        hasFailed: true,
      };

    default:
      return state;
  }
};

export default providers;
