import React from 'react';
import ReactDocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';

const DocumentTitle = ({ children, title, style = {} }) => (
  <ReactDocumentTitle title={`${title} - Medi-Share`} style={style}>
    {children}
  </ReactDocumentTitle>
);

DocumentTitle.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default DocumentTitle;
