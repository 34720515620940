import _ from 'lodash';
import {
  extractPersonData,
  updateState,
  isContactInfoDirty,
  isContactInfoValid,
  contactInfoChanges,
} from '../utils/reducerUtils/profile';
import {
  SET_LOADING,
  AUTH_ERROR,
  FETCH_USER_DATA_SUCCESS,
  INVALIDATE_TOKEN,
  INVALIDATE_TOKEN_ERROR,
  UPDATE_CONTACT_INFO_FIELD,
  TOGGLE_MAILING_SAME_AS_BILLING,
  ON_SAVE_CONTACT_INFO_REQUEST,
  ON_SAVE_CONTACT_INFO_SUCCESS,
  ON_SAVE_CONTACT_INFO_FAILURE,
  TOGGLE_DISPLAY_MESSAGE,
  SET_AUTH_DATA,
  HIPAA_SET_DATA,
  HIPAA_ADD_AUTHORIZED_USER,
  HIPAA_REMOVE_AUTHORIZED_USER,
  PAPERLESS_SET_DATA,
  PAPERLESS_EDIT_DATA,
  SET_MESSAGE_METHODS,
  EDIT_MESSAGE_METHOD,
  TOGGLE_DISPLAY_PRICE_CHANGES,
  SET_PRICE_CHANGES,
} from '../redux-constants';

export const initialState = {
  priceChanges: {
    priceChanges: [],
    displayPriceChanges: false,
  },
  isLoading: true,
  auth: {
    id: null,
    token: null,
  },
  contactInfo: {
    person: {
      id: '',
      netsuite_id: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      gender: '',
      marital_status: '',
      person_type: '',
      birthday: '',
      primary_phone: '',
      mobile_phone: '',
      work_phone: '',
      email: '',
    },
    mailing_address: {
      // street becomes line1
      line1: '',
      city: '',
      state: '',
      // zipcode becomes zip
      zip: '',
      latitude: '',
      longitude: '',
      verified_date: '',
    },
    billing_address: {
      // street becomes line1
      line1: '',
      city: '',
      state: '',
      // zipcode becomes zip
      zip: '',
      latitude: '',
      longitude: '',
      verified_date: '',
    },
    isDirty: false,
    isSaving: false,
    displayError: false,
    displaySaved: false,
    isValid: false,
    mailingSameAsBilling: false,
    expanded: false,
    error: null,
    changes: {},
  },
  program: null,
  hipaa: {
    contact_authorizations: [],
    unauthorized_household_members: [],
    relationship_types: [],
  },
  paperless_statements: {
    explanation_of_sharing_statement: false,
    manna_monthly_share_notice: false,
    medishare_monthly_share_notice: false,
  },
  message_methods: {},
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    case SET_AUTH_DATA: {
      const auth = Object.assign({}, { id: action.id, token: action.token });
      return {
        ...state,
        auth,
      };
    }

    case AUTH_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }

    case FETCH_USER_DATA_SUCCESS: {
      const { mailing_address, billing_address } = action.response.contact;
      let mailingSameAsBilling = false;
      if (_.isEqual(mailing_address, billing_address)) {
        mailingSameAsBilling = true;
      }

      const contactInfo = Object.assign(
          {},
          {
            ...state.contactInfo,
            person: extractPersonData(action.response.contact),
            mailing_address: {
              line1: !mailing_address.street ? '' : mailing_address.street,
              city: !mailing_address.city ? '' : mailing_address.city,
              state: !mailing_address.state ? '' : mailing_address.state,
              zip: !mailing_address.zipcode ? '' : mailing_address.zipcode,
              latitude: !mailing_address.latitude
                ? ''
                : `${mailing_address.latitude}`,
              longitude: !mailing_address.longitude
                ? ''
                : `${mailing_address.longitude}`,
              verified_date: !mailing_address.verified_date
                ? ''
                : mailing_address.verified_date,
            },
            billing_address: {
              line1: !billing_address.street ? '' : billing_address.street,
              city: !billing_address.city ? '' : billing_address.city,
              state: !billing_address.state ? '' : billing_address.state,
              zip: !billing_address.zipcode ? '' : billing_address.zipcode,
              latitude: !billing_address.latitude
                ? ''
                : `${billing_address.latitude}`,
              longitude: !billing_address.longitude
                ? ''
                : `${billing_address.longitude}`,
              verified_date: !billing_address.verified_date
                ? ''
                : billing_address.verified_date,
            },
            mailingSameAsBilling,
          }
        ),
        originalContactInfo = Object.assign(
          {},
          {
            person: extractPersonData(action.response.contact),
            mailing_address: {
              line1: !mailing_address.street ? '' : mailing_address.street,
              city: !mailing_address.city ? '' : mailing_address.city,
              state: !mailing_address.state ? '' : mailing_address.state,
              zip: !mailing_address.zipcode ? '' : mailing_address.zipcode,
              latitude: !mailing_address.latitude
                ? ''
                : `${mailing_address.latitude}`,
              longitude: !mailing_address.longitude
                ? ''
                : `${mailing_address.longitude}`,
              verified_date: !mailing_address.verified_date
                ? ''
                : mailing_address.verified_date,
            },
            billing_address: {
              line1: !billing_address.street ? '' : billing_address.street,
              city: !billing_address.city ? '' : billing_address.city,
              state: !billing_address.state ? '' : billing_address.state,
              zip: !billing_address.zipcode ? '' : billing_address.zipcode,
              latitude: !billing_address.latitude
                ? ''
                : `${billing_address.latitude}`,
              longitude: !billing_address.longitude
                ? ''
                : `${billing_address.longitude}`,
              verified_date: !billing_address.verified_date
                ? ''
                : billing_address.verified_date,
            },
            mailingSameAsBilling,
          }
        );

      const newState = {
        ...state,
        isLoading: false,
        contactInfo: {
          ...contactInfo,
          isValid: isContactInfoValid(contactInfo),
        },
        originalContactInfo,
      };

      return newState;
    }

    case INVALIDATE_TOKEN: {
      return {
        ...state,
      };
    }

    case INVALIDATE_TOKEN_ERROR: {
      return {
        ...state,
      };
    }

    /** Contact Info */
    case UPDATE_CONTACT_INFO_FIELD: {
      let newState = updateState(state, action.path, action.newValue),
        { path } = action;

      if (
        path.indexOf('contactInfo.mailing_address') !== -1 &&
        state.contactInfo.mailingSameAsBilling
      ) {
        newState = updateState(
          state,
          path.replace('mailing_address', 'billing_address'),
          action.newValue
        );
      }
      return {
        ...newState,
        contactInfo: {
          ...newState.contactInfo,
          isDirty: isContactInfoDirty(
            newState.contactInfo,
            newState.originalContactInfo
          ),
          isValid: isContactInfoValid(newState.contactInfo),
          changes: contactInfoChanges(
            newState.contactInfo,
            newState.originalContactInfo
          ),
        },
      };
    }

    case TOGGLE_MAILING_SAME_AS_BILLING: {
      const toggledMailingSameAsBilling = !state.contactInfo
        .mailingSameAsBilling;

      let newState = updateState(
        state,
        'contactInfo.mailingSameAsBilling',
        toggledMailingSameAsBilling
      );

      if (toggledMailingSameAsBilling) {
        newState = updateState(
          newState,
          'contactInfo.billing_address',
          Object.assign({}, newState.contactInfo.mailing_address)
        );
      }

      return {
        ...newState,
        contactInfo: {
          ...newState.contactInfo,
          isDirty: isContactInfoDirty(
            newState.contactInfo,
            newState.originalContactInfo
          ),
          changes: contactInfoChanges(
            newState.contactInfo,
            newState.originalContactInfo
          ),
        },
      };
    }

    case ON_SAVE_CONTACT_INFO_REQUEST: {
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          isSaving: true,
        },
      };
    }

    case ON_SAVE_CONTACT_INFO_SUCCESS: {
      let mailingSameAsBilling = false;
      if (
        _.isEqualWith(
          state.contactInfo.mailing_address,
          state.contactInfo.billing_address
        )
      )
        mailingSameAsBilling = true;

      const originalContactInfo = Object.assign(
        {},
        {
          person: Object.assign({}, state.contactInfo.person),
          mailing_address: Object.assign({}, state.contactInfo.mailing_address),
          billing_address: Object.assign({}, state.contactInfo.billing_address),
        }
      );

      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          isDirty: false,
          isSaving: false,
          displayError: false,
          mailingSameAsBilling,
          changes: {},
        },
        originalContactInfo,
      };
    }

    case ON_SAVE_CONTACT_INFO_FAILURE: {
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          isSaving: false,
          displayError: true,
        },
      };
    }

    case TOGGLE_DISPLAY_MESSAGE: {
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          [action.messageType]: action.bool,
        },
      };
    }

    case HIPAA_SET_DATA: {
      return {
        ...state,
        hipaa: action.data,
      };
    }

    case HIPAA_ADD_AUTHORIZED_USER: {
      const { contact_authorizations } = state.hipaa;
      let { unauthorized_household_members } = state.hipaa;
      unauthorized_household_members = unauthorized_household_members.filter(
        contact =>
          Number(contact.contact_uid) !== Number(action.user.contact_uid)
      );
      return {
        ...state,
        hipaa: {
          ...state.hipaa,
          contact_authorizations: contact_authorizations.concat(action.user),
          unauthorized_household_members,
        },
      };
    }

    case HIPAA_REMOVE_AUTHORIZED_USER: {
      let {
        contact_authorizations,
        unauthorized_household_members,
      } = state.hipaa;
      const unauthorizedMember = contact_authorizations.filter(
        contact =>
          contact.contact_authorization_id === action.contact_authorization_id
      )[0];
      unauthorized_household_members.push(unauthorizedMember);
      contact_authorizations = contact_authorizations.filter(
        contact =>
          contact.contact_authorization_id !== action.contact_authorization_id
      );
      return {
        ...state,
        hipaa: {
          ...state.hipaa,
          contact_authorizations,
          unauthorized_household_members,
        },
      };
    }

    case PAPERLESS_SET_DATA: {
      return {
        ...state,
        paperless_statements: action.paperless_statements,
      };
    }

    case PAPERLESS_EDIT_DATA: {
      return {
        ...state,
        paperless_statements: {
          ...state.paperless_statements,
          ...action.paperless_statements,
        },
      };
    }

    case SET_MESSAGE_METHODS: {
      return {
        ...state,
        message_methods: action.message_methods,
      };
    }

    case EDIT_MESSAGE_METHOD: {
      let message_methods = JSON.parse(JSON.stringify(state.message_methods));
      message_methods[action.message_method.code] = action.message_method;
      return {
        ...state,
        message_methods,
      };
    }

    case TOGGLE_DISPLAY_PRICE_CHANGES: {
      return {
        ...state,
        priceChanges: {
          ...state.priceChanges,
          displayPriceChanges: action.bool,
        },
      };
    }

    case SET_PRICE_CHANGES: {
      return {
        ...state,
        priceChanges: {
          ...state.priceChanges,
          priceChanges: action.priceChanges,
        },
      };
    }

    default:
      return state;
  }
};

export default profile;
