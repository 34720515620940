import { UPDATE_VIEW } from '../redux-constants';

export const initialState = {
  innerWidth: window.innerWidth,
};

const view = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_VIEW:
      return {
        ...state,
        ...action.view,
      };
    default:
      return state;
  }
};

export default view;
