import _ from 'lodash';

export function extractPersonData(data) {
  return {
    id: data.id,
    netsuite_id: data.netsuite_id,
    first_name: data.first_name,
    middle_name: data.middle_name,
    last_name: data.last_name,
    gender: data.gender,
    marital_status: data.marital_status,
    person_type: data.person_type,
    birthday: data.birthday,
    primary_phone: data.primary_phone,
    mobile_phone: data.mobile_phone,
    work_phone: data.work_phone,
    email: data.email,
    has_spouse: data.has_spouse,
  };
}

export function updateState(state, path, newValue) {
  let newState = state;

  _.update(newState, path, () => {
    return newValue;
  });

  return newState;
}

export function isContactInfoDirty(state, originalState) {
  return (
    !_.isEqual(state.person, originalState.person) ||
    !_.isEqual(state.mailing_address, originalState.mailing_address) ||
    !_.isEqual(state.billing_address, originalState.billing_address)
  );
}

function difference(object, base) {
  function changes(object, base) {
    return _.transform(object, function(result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
}

export function contactInfoChanges(state, originalState) {
  let personChanges = difference(state.person, originalState.person);
  const mailingChanges = difference(
    state.mailing_address,
    originalState.mailing_address
  );
  delete mailingChanges.addressVerified;
  const billingChanges = difference(
    state.billing_address,
    originalState.billing_address
  );
  delete billingChanges.addressVerified;

  let changes = { ...personChanges };
  if (!_.isEmpty(mailingChanges, true)) {
    changes['mailing_address'] = mailingChanges;
  }
  if (!_.isEmpty(billingChanges, true)) {
    changes['billing_address'] = billingChanges;
  }
  return changes;
}

export function isContactInfoValid(contactInfo) {
  const { primary_phone, mobile_phone, marital_status } = contactInfo.person;
  return (
    contactInfo.mailing_address.street !== '' &&
    contactInfo.mailing_address.city !== '' &&
    contactInfo.mailing_address.state !== '' &&
    contactInfo.mailing_address.zip !== '' &&
    contactInfo.billing_address.street !== '' &&
    contactInfo.billing_address.city !== '' &&
    contactInfo.billing_address.state !== '' &&
    contactInfo.billing_address.zip !== '' &&
    marital_status !== null &&
    ((primary_phone && primary_phone.length > 9) || primary_phone === null) &&
    ((mobile_phone && mobile_phone.length > 9) || mobile_phone === null)
  );
}
