import {
  GET_PASSWORD_REQUIREMENTS,
  SET_DEFAULT_PASSWORD_REQUIREMENTS,
  SET_PASSWORD_AUTHENTICATE_TOKEN,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR_MESSAGE,
  SET_PASSWORD_LOADING,
  CLEAR_PASSWORD_ERROR_MESSAGE,
  UPDATE_NEW_PASSWORD_VALIDITY,
} from '../redux-constants';

export const initialState = {
  stateToken: null,
  userEmailParts: null,
  success: null,
  errorMessage: null,
  isLoading: false,
  newTokenNeeded: false,
  passwordRequirements: {},
  defaultPasswordRequirements: false,
  newPasswordValid: false,
};

const setPassword = (state = initialState, action) => {
  switch (action.type) {
    case GET_PASSWORD_REQUIREMENTS:
      return {
        ...state,
        passwordRequirements: action.passwordRequirements,
        defaultPasswordRequirements: false,
        isLoading: false,
      };
    case SET_DEFAULT_PASSWORD_REQUIREMENTS:
      return {
        ...state,
        defaultPasswordRequirements: true,
      };
    case SET_PASSWORD_AUTHENTICATE_TOKEN:
      return {
        ...state,
        stateToken: action.stateToken,
        userEmailParts: action.userEmailParts,
        success: null,
        errorMessage: null,
        isLoading: false,
      };
    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        success: action.success,
        isLoading: false,
      };
    case SET_PASSWORD_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isLoading: false,
        newTokenNeeded: action.newTokenNeeded,
      };
    case SET_PASSWORD_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case CLEAR_PASSWORD_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: null,
      };
    case UPDATE_NEW_PASSWORD_VALIDITY:
      return {
        ...state,
        newPasswordValid: action.valid,
      };
    default:
      return state;
  }
};

export default setPassword;
