import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SaveButton from '../SaveButton';
import PasswordRequirements from '../../PasswordRequirements';

import { changePassword } from '../../../actions/profile';

import { fetchPasswordRequirements } from '../../../actions/setPassword';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      passwordConfirm: '',
      passwordVerified: false,
      passwordRequirementsArray: [],
      showActivationPage: false,
      success: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.props.fetchPasswordRequirements();
  }

  brieflyTogglePasswordSuccessState() {
    this.setState({
      success: true,
      newPassword: '',
      passwordConfirm: '',
    });
    setTimeout(() => this.setState({ success: false }), 3000);
  }

  setErrorIfPresent(error) {
    if (!error) {
      this.brieflyTogglePasswordSuccessState();
    } else {
      this.setState({ errorMessage: error });
    }
  }

  clearErrorsIfPresent() {
    if (this.state.errorMessage !== null) this.setState({ errorMessage: null });
  }

  displayErrorMessage() {
    this.setState({
      errorMessage: 'New Password and Confirm Password do not match.',
    });
  }

  changePassword() {
    if (this.state.newPassword === this.state.passwordConfirm) {
      this.props.changePassword(this.state.newPassword, error =>
        this.setErrorIfPresent(error)
      );
    } else {
      this.displayErrorMessage();
    }
  }

  render() {
    const {
      passwordRequirements: { complexity },
    } = this.props.data;
    return (
      <div>
        <div>
          {!!this.state.errorMessage && (
            <p style={{ color: 'red', paddingLeft: 20 }}>
              {this.state.errorMessage}
            </p>
          )}
        </div>
        <div>
          {complexity && (
            <PasswordRequirements
              oldPassword={this.state.oldPassword}
              password={this.state.newPassword}
              passwordConfirm={this.state.passwordConfirm}
              data={this.props.data}
            />
          )}
          <input
            name="newPassword"
            type="password"
            autoComplete="off"
            value={this.state.newPassword}
            placeholder="New Password"
            onChange={({ target: { value: newPassword } }) => {
              this.setState({ newPassword });
              this.clearErrorsIfPresent();
            }}
            autoFocus
          />
          <input
            name="passwordConfirm"
            type="password"
            autoComplete="off"
            value={this.state.passwordConfirm}
            placeholder="Confirm Password"
            onChange={({ target: { value: passwordConfirm } }) => {
              this.setState({ passwordConfirm });
              this.clearErrorsIfPresent();
            }}
          />
          <SaveButton
            onSave={() => this.changePassword()}
            displaySaved={this.state.success}
            savedMessage={'Saved!'}
            disabled={!this.props.data.newPasswordValid}
          />
        </div>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  fetchPasswordRequirements: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  data: state.setPassword,
  contactInfo: state.profile.contactInfo,
});

const mapDispatchToProps = dispatch => ({
  fetchPasswordRequirements: () => dispatch(fetchPasswordRequirements()),
  changePassword: (newPassword, cb) =>
    dispatch(changePassword(newPassword, cb)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
