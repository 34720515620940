exports.API_ROOT_URL = process.env.REACT_APP_API_ROOT_URL;
exports.PORTAL_ROOT_URL = process.env.REACT_APP_PORTAL_ROOT_URL;
exports.API_PROXY_URL = '/api';
exports.API_AUTH_URL = `${exports.API_ROOT_URL}/v2/contacts/authenticate`;
exports.API_INVALIDATE_URL = `${exports.API_ROOT_URL}/v2/contacts/invalidate`;
exports.API_CONTACTS_URL = `${exports.API_ROOT_URL}/v2/contacts`;
exports.API_PASSWORDS_URL = `${exports.API_ROOT_URL}/v1/passwords`;
exports.API_LOGIN_URL = `${exports.API_ROOT_URL}/login`;
exports.API_HIPAA_URL = `${exports.API_ROOT_URL}/v2/hipaa`;
exports.API_HIPAA_AUTH_URL = `${exports.API_HIPAA_URL}/authorizations`;
exports.API_PAPERLESS_URL = `${exports.API_ROOT_URL}/v2/paperless_statements`;
exports.API_CHANGE_PASSWORD_URL = `${exports.API_ROOT_URL}/v2/password`;
exports.API_MESSAGE_METHODS_URL = `${
  exports.API_ROOT_URL
}/v2/hipaa/message_methods/`;
