import { SHOW_MODAL, HIDE_MODAL } from '../redux-constants';

export const initialState = {
  visible: false,
  content: null,
};

const modal = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        visible: true,
        content: action.content,
      };
    case HIDE_MODAL:
      return {
        visible: false,
        content: null,
      };
    default:
      return state;
  }
};

export default modal;
