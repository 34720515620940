import React from 'react';
import Sharing from '../../assets/img/sharing.png';
import Twitter from '../../assets/img/icon-twitter.png';
import Facebook from '../../assets/img/icon-facebook.png';
import Pinterest from '../../assets/img/icon-pinterest.png';
import Instagram from '../../assets/img/icon-instagram.png';

import './styles.css';

const Footer = () => (
  <div className="footer">
    <div className="inner">
      <div className="top-footer">
        <div className="size1of3">
          <ul className="nav-links">
            <li>
              <a href={`${process.env.REACT_APP_WSOL_URL}/members/`}>
                Member Center
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_WSOL_URL}/our-ministry/`}>
                About Us
              </a>
            </li>
            <li>
              <a
                href={`${
                  process.env.REACT_APP_WSOL_URL
                }/our-ministry/who-we-are/careers/`}
              >
                Employment
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_WSOL_URL}/pressroom/`}
              >
                Press Room
              </a>
            </li>
            <li>
              <a
                href={`${
                  process.env.REACT_APP_WSOL_URL
                }/utility/website-terms-of-use/`}
              >
                Website Terms of Use
              </a>
            </li>
            <li>
              <a
                href={`${
                  process.env.REACT_APP_WSOL_URL
                }/utility/internet-privacy/`}
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>

        <div className="size1of3" style={{ float: 'right' }}>
          <div className="social">
            <ul className="fourths">
              <li>
                <a
                  href="https://twitter.com/MyChristianCare"
                  className="twitter button"
                >
                  <img src={Twitter} alt="Twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/MyChristianCare"
                  className="facebook button"
                >
                  <img src={Facebook} alt="Facebook" />
                </a>
              </li>
              <li>
                <a
                  href="http://www.pinterest.com/mychristiancare/"
                  className="pinterest button"
                >
                  <img src={Pinterest} alt="Pinterest" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/medi_share/"
                  className="instagram button"
                >
                  <img src={Instagram} alt="Instagram" />
                </a>
              </li>
            </ul>
            <a href="tel:1-800-PSALM-23" className="phone button">
              1-800-PSALM-23
            </a>
          </div>
        </div>

        <div className="size1of3">
          <ul className="nav-links">
            <li>
              <a href={`${process.env.REACT_APP_WSOL_URL}/contact-us-link/`}>
                Contact Information
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_WSOL_URL}/utility/site-map/`}>
                Site Map
              </a>
            </li>
            <li>
              <a
                href={`${
                  process.env.REACT_APP_WSOL_URL
                }/medi-share/what-is-medishare/how-medi-share-works/find-a-provider/`}
              >
                Find a Provider
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_WSOL_URL}/forproviders/`}>
                For Providers
              </a>
            </li>
            <li>
              <a
                href={`${
                  process.env.REACT_APP_WSOL_URL
                }/medi-share/what-is-medishare/how-medi-share-works/medi-share-guidelines/`}
              >
                Medi-Share Guidelines
              </a>
            </li>
            <li>
              <a
                href={`${
                  process.env.REACT_APP_WSOL_URL
                }/medi-share/what-is-medishare/healthcare-reform/`}
              >
                Healthcare Reform
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://blog.medi-share.org/"
              >
                Blog
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div>
        <ul className="legal">
          <li>
            <img
              src={Sharing}
              alt="Sharing, Faith, Lives, Talents, Resources"
            />
          </li>
          <li>&#169;2018 Christian Care Ministry. All Rights Reserved</li>
          <li className="disclaimer">
            Members in a Health Care Sharing Ministry such as Medi-Share are
            exempt from the individual mandate in the Patient Protection and
            Affordable Care Act found in 26 United States Code
            &sect;5000A(d)(2)(B). Medi-Share is not insurance. It is a
            not-for-profit ministry and is not guaranteed in any way. Medi-Share
            is exempt from insurance regulation. The following states require a
            notice for Medi-Share to qualify for an exemption from insurance
            regulation. While Medi-Share is not insurance and therefore, need
            not qualify for such exemptions, CCM has elected to publish these
            exemptions. You can review the disclosure required for the state in
            which you reside:{' '}
            <a
              title="KY, MD, PA, WI - All Other States"
              href={`${
                process.env.REACT_APP_WSOL_URL
              }/our-ministry/disclosures/`}
            >
              KY, MD, PA, WI - All Other States.
            </a>
            &nbsp;
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default Footer;
