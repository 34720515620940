import React from 'react';
import { connect } from 'react-redux';

import { hideModal } from '../../actions/modal';

const Modal = ({ modal: { visible, content }, hideModal }) => (
  <div
    style={{
      position: 'fixed',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 500,
      opacity: visible ? 1 : 0,
      transition: 'opacity 0.25s',
      pointerEvents: visible ? 'auto' : 'none',
    }}
  >
    <div
      onClick={() => hideModal()}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.70)',
      }}
    />
    <div
      style={{
        position: 'relative',
        margin: 'auto',
        width: 'calc(100% - 40px)',
        maxWidth: 450,
        maxHeight: 'calc(100vh - 150px)',
        overflow: 'scroll',
        backgroundColor: 'white',
        borderRadius: 12,
      }}
    >
      {content}
    </div>
  </div>
);

const mapStateToProps = state => ({
  modal: state.modal,
});

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
