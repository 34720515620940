import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Qs from 'qs';

import Page from '../Page';
import Spinner from '../Spinner';

import {
  authenticateToken,
  fetchPasswordRequirements,
  setPassword,
} from '../../actions/setPassword';

import './styles.css';
import PasswordRequirements from '../PasswordRequirements';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirm: '',
      oldPassword: '',
      passwordVerified: false,
      passwordRequirementsArray: [],
      showActivationPage: false,
    };
    this.resetToken = Qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }).recovery_token;
    this.activationToken = Qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }).activation_token;
    document.body.className += ' setPassword';
  }

  componentDidMount() {
    this.props.fetchPasswordRequirements();
    if (this.resetToken)
      this.props.authenticateToken(this.resetToken, 'recover');
    if (this.activationToken) {
      this.setState({ showActivationPage: true });
      this.props.authenticateToken(this.activationToken, 'activate');
    }
  }

  componentWillUnmount() {
    document.body.className = document.body.className.replace(
      ' setPassword',
      ''
    );
  }

  passwordsMatch() {
    return (
      this.state.password === this.state.passwordConfirm &&
      this.state.password !== ''
    );
  }

  render() {
    const { data } = this.props;
    if (this.state.showActivationPage) {
      return (
        <Page title="Registration Successful">
          <h1>Registration Successful!</h1>
          <hr />
          <div>
            <p>Your account registration was successful!</p>
            <p>Click the button below to set your password.</p>
            <button
              onClick={() => {
                this.setState({ showActivationPage: false });
              }}
            >
              Set Your Password
            </button>
          </div>
        </Page>
      );
    }
    const loginPage = `${process.env.REACT_APP_WSOL_URL}/login`;
    return (
      <Page title="Set Your Password">
        {this.activationToken && <h1>Create Password</h1>}
        {this.resetToken && <h1>Reset Password</h1>}
        <hr />
        {data.isLoading && <Spinner />}
        {data.errorMessage && !data.isLoading && <p>{data.errorMessage}</p>}
        {data.errorMessage && data.newTokenNeeded && !data.isLoading && (
          <div>
            <p>
              Please submit a new password reset request{' '}
              <Link to="/account/forgot-password">here</Link>
            </p>
          </div>
        )}
        {data.success && !data.isLoading && (
          <div>
            <h3>Success!</h3>
            <p>You have successfully reset your password. Please login.</p>
            <p>
              <a href={loginPage}>Go to Login Page</a>
            </p>
          </div>
        )}
        {!data.success && data.stateToken && !data.isLoading && (
          <div>
            <PasswordRequirements
              oldPassword={this.state.oldPassword}
              password={this.state.password}
              passwordConfirm={this.state.passwordConfirm}
              data={this.props.data}
            />
            <input
              name="password"
              type="password"
              value={this.state.password}
              placeholder="New Password"
              onChange={({ target: { value: password } }) => {
                this.setState({ password });
              }}
              autoFocus
            />
            <input
              name="passwordConfirm"
              type="password"
              value={this.state.passwordConfirm}
              placeholder="Confirm Password"
              onChange={({ target: { value: passwordConfirm } }) =>
                this.setState({ passwordConfirm })
              }
            />
          </div>
        )}
        {!data.success &&
          !data.isLoading &&
          this.state.password !== '' &&
          !this.passwordsMatch() && (
            <p style={{ color: 'red' }}>Your passwords do not match</p>
          )}
        {!data.success && !data.isLoading && this.passwordsMatch() && (
          <div>
            <button onClick={() => this.props.setPassword(this.state.password)}>
              Save
            </button>
          </div>
        )}
      </Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  authenticateToken: (token, type) => dispatch(authenticateToken(token, type)),
  fetchPasswordRequirements: () => dispatch(fetchPasswordRequirements()),
  setPassword: password => dispatch(setPassword(password)),
});

const mapStateToProps = state => ({
  data: state.setPassword,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
