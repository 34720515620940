import React from 'react';

export default props => {
  const iconColor = props.iconColor || '#3c3c3c';
  return (
    <svg width="24px" height="18px" viewBox="0 0 24 18">
      <path
        fill={iconColor}
        d="M8.15146875,17.5970625 L0.35146875,9.7970625 C-0.117140625,9.32845313 -0.117140625,8.56865625 0.35146875,8.1 L2.04848438,6.4029375 C2.51709375,5.93428125 3.2769375,5.93428125 3.74554688,6.4029375 L9,11.6573438 L20.2544531,0.4029375 C20.7230625,-0.065671875 21.4829063,-0.065671875 21.9515156,0.4029375 L23.6485313,2.1 C24.1171406,2.56860938 24.1171406,3.32840625 23.6485313,3.7970625 L9.84853125,17.5971094 C9.379875,18.0657188 8.62007813,18.0657188 8.15146875,17.5970625 L8.15146875,17.5970625 Z"
        id="path-1"
      />
    </svg>
  );
};
