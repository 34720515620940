import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SaveButton extends Component {
  render() {
    const {
      text,
      onSave,
      savedMessage,
      disabled,
      displaySaved,
      'data-testid': dataTestId,
    } = this.props;
    return (
      <div style={{ marginTop: 20 }}>
        <button data-testid={dataTestId} disabled={disabled} onClick={onSave}>
          {text ? text : 'Save'}
        </button>
        {'  '}
        {displaySaved && <span style={{ color: 'gray' }}>{savedMessage}</span>}
      </div>
    );
  }
}

SaveButton.propTypes = {
  text: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  savedMessage: PropTypes.string,
  disabled: PropTypes.bool,
  displaySaved: PropTypes.bool,
};

export default SaveButton;
