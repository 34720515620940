import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateNewPasswordValidity } from '../../actions/setPassword';

class PasswordRequirements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordRequirementsArray: [],
    };
  }

  componentDidMount() {
    this.showPasswordRequirements();
  }

  showPasswordRequirements() {
    if (!this.props.data.passwordRequirements.complexity) {
      return;
    }
    const requirements = [];
    const complexity = this.props.data.passwordRequirements.complexity;

    if (complexity.minLength > 0) {
      requirements.push({
        type: 'minLength',
        color: 'red',
        requirement: complexity.minLength,
        requirementMet: false,
        text: `A minimum of ${complexity.minLength} characters`,
      });
    }
    if (complexity.minLowerCase > 0) {
      const lowerCase = complexity.minLowerCase;
      requirements.push({
        type: 'minLowerCase',
        color: 'red',
        requirement: lowerCase,
        requirementMet: false,
        text:
          lowerCase === 1
            ? `${lowerCase} lowercase letter`
            : `${lowerCase} lowercase letters`,
      });
    }
    if (complexity.minNumber > 0) {
      const num = complexity.minNumber;
      requirements.push({
        type: 'minNumber',
        color: 'red',
        requirement: num,
        requirementMet: false,
        text: num === 1 ? `${num} number` : `${num} numbers`,
      });
    }
    if (complexity.minSymbol > 0) {
      const sym = complexity.minSymbol;
      requirements.push({
        type: 'minSymbol',
        color: 'red',
        requirement: sym,
        requirementMet: false,
        text: sym === 1 ? `${sym} symbol` : `${sym} symbols`,
      });
    }
    if (complexity.minUpperCase > 0) {
      const upperCase = complexity.minUpperCase;
      requirements.push({
        type: 'minUpperCase',
        color: 'red',
        requirement: upperCase,
        requirementMet: false,
        text:
          upperCase === 1
            ? `${upperCase} uppercase letter`
            : `${upperCase} uppercase letters`,
      });
    }
    if (complexity.excludeUsername) {
      requirements.push({
        type: 'excludeUsername',
        color: 'red',
        requirement: complexity.excludeUsername,
        requirementMet: false,
        text: `No part of your username can be used`,
      });
    }
    requirements.push({
      type: 'confirmationMustMatch',
      color: 'red',
      requirementMet: false,
      text: `New password and confirm password must match`,
    });
    this.setState({ passwordRequirementsArray: requirements });
  }

  findRequirementAndChangeColor(type, color) {
    const req = this.state.passwordRequirementsArray.find(e => e.type === type);
    req.color = color;
    color === 'red'
      ? (req.requirementMet = false)
      : (req.requirementMet = true);
    this.passwordMeetsRequirements();
  }

  checkRequirements() {
    const usernameCheck = this.props.data
      ? this.props.data.userEmailParts
      : null;
    this.state.passwordRequirementsArray.forEach(rule => {
      if (rule.type === 'minLength') {
        this.props.password.length >= rule.requirement
          ? this.findRequirementAndChangeColor('minLength', 'green')
          : this.findRequirementAndChangeColor('minLength', 'red');
      }
      if (rule.type === 'minNumber') {
        const numberCheck = new RegExp(`[0-9]{${rule.requirement},}`, 'g');
        this.props.password.search(numberCheck) !== -1
          ? this.findRequirementAndChangeColor('minNumber', 'green')
          : this.findRequirementAndChangeColor('minNumber', 'red');
      }
      if (rule.type === 'minUpperCase') {
        const upperCheck = new RegExp(`[A-Z]{${rule.requirement},}`, 'g');
        this.props.password.search(upperCheck) !== -1
          ? this.findRequirementAndChangeColor('minUpperCase', 'green')
          : this.findRequirementAndChangeColor('minUpperCase', 'red');
      }
      if (rule.type === 'minLowerCase') {
        const lowerCheck = new RegExp(`[a-z]{${rule.requirement},}`, 'g');
        this.props.password.search(lowerCheck) !== -1
          ? this.findRequirementAndChangeColor('minLowerCase', 'green')
          : this.findRequirementAndChangeColor('minLowerCase', 'red');
      }
      if (rule.type === 'minSymbol') {
        const symbolCheck = new RegExp(`\\W{${rule.requirement},}`, 'g');
        this.props.password.search(symbolCheck) !== -1
          ? this.findRequirementAndChangeColor('minSymbol', 'green')
          : this.findRequirementAndChangeColor('minSymbol', 'red');
      }
      if (rule.type === 'excludeUsername') {
        this.findRequirementAndChangeColor('excludeUsername', 'green');
        if (usernameCheck) {
          usernameCheck.some(
            value => this.props.password.toLowerCase().search(value) !== -1
          )
            ? this.findRequirementAndChangeColor('excludeUsername', 'red')
            : this.findRequirementAndChangeColor('excludeUsername', 'green');
        }
      }
      if (rule.type === 'confirmationMustMatch') {
        this.props.passwordConfirm.length > 7 &&
        this.props.password === this.props.passwordConfirm
          ? this.findRequirementAndChangeColor('confirmationMustMatch', 'green')
          : this.findRequirementAndChangeColor('confirmationMustMatch', 'red');
      }
    });
  }

  passwordMeetsRequirements() {
    const array = this.state.passwordRequirementsArray;
    for (let i = 0; i < array.length; i++) {
      if (array[i].requirementMet === false) {
        this.props.updateNewPasswordValidity(false);
        return;
      }
    }
    this.props.updateNewPasswordValidity(true);
  }

  render() {
    this.checkRequirements();
    if (this.props.defaultPasswordRequirements) {
      return (
        <p>
          Password Requirements:
          <ul>
            <li>Password fields must match</li>
            <li>Other requirements currently unavailable</li>
          </ul>
        </p>
      );
    }
    return (
      <ul>
        {this.state.passwordRequirementsArray.map((requirement, index) => {
          return (
            <li
              key={index}
              style={{
                color: requirement.color === '' ? null : requirement.color,
                fontWeight: requirement.requirementMet ? 'bold' : null,
              }}
            >
              {requirement.text}
            </li>
          );
        })}
      </ul>
    );
  }
}

const mapStateToProps = state => ({
  defaultPasswordRequirements: state.setPassword.defaultPasswordRequirements,
});

const mapDispatchToProps = dispatch => ({
  updateNewPasswordValidity: isValid =>
    dispatch(updateNewPasswordValidity(isValid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordRequirements);
