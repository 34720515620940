import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './styles.css';

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentHeight: 0,
      hover: false,
    };
    this.toggleHover = this.toggleHover.bind(this);
  }

  componentDidMount() {
    this.setContentHeight();
  }

  componentDidUpdate() {
    this.setContentHeight();
  }

  setContentHeight() {
    const content = document.getElementById(
      `${this.props.sectionName}-content`
    );
    if (content) {
      if (this.state.contentHeight !== content.scrollHeight) {
        this.setState({ contentHeight: content.scrollHeight });
      }
    }
  }

  toggleHover() {
    this.setState({ hover: !this.state.hover });
  }

  render() {
    const { expanded, title, sectionName } = this.props;
    const isExpanded = expanded === sectionName;
    return (
      <div className="accordionSection">
        <h3
          className={`${sectionName}-title accordionTitle`}
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
          onClick={() => this.props.toggleSectionExpansion(sectionName)}
        >
          <span className={`accordionToggle ${isExpanded ? 'current' : ''}`}>
            +
          </span>
          {title}
        </h3>
        <div
          id={`${sectionName}-content`}
          className="accordion"
          style={{ height: isExpanded ? this.state.contentHeight : 0 }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

Section.propTypes = {
  expanded: PropTypes.string,
  title: PropTypes.string.isRequired,
  sectionName: PropTypes.string.isRequired,
  toggleSectionExpansion: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

Section.defaultProps = {
  expanded: null,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps)(Section);
