import {
  AUTH_ERROR,
  EDIT_MESSAGE_METHOD,
  FETCH_USER_DATA_SUCCESS,
  HIPAA_ADD_AUTHORIZED_USER,
  HIPAA_REMOVE_AUTHORIZED_USER,
  HIPAA_SET_DATA,
  INVALIDATE_TOKEN,
  INVALIDATE_TOKEN_ERROR,
  ON_SAVE_CONTACT_INFO_FAILURE,
  ON_SAVE_CONTACT_INFO_REQUEST,
  ON_SAVE_CONTACT_INFO_SUCCESS,
  PAPERLESS_EDIT_DATA,
  PAPERLESS_SET_DATA,
  SET_AUTH_DATA,
  SET_LOADING,
  SET_MESSAGE_METHODS,
  SET_PRICE_CHANGES,
  TOGGLE_DISPLAY_MESSAGE,
  TOGGLE_DISPLAY_PRICE_CHANGES,
  TOGGLE_MAILING_SAME_AS_BILLING,
  UPDATE_CONTACT_INFO_FIELD,
} from '../redux-constants';
import BaseAPI from '../utils/API/BaseAPI';
import Constants from '../utils/API/Constants';
import _ from 'lodash';

export function authenticateUser(token) {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    BaseAPI.create(Constants.API_AUTH_URL, null, token)
      .then(data => {
        dispatch({
          type: SET_AUTH_DATA,
          id: data.id,
          token,
        });
        dispatch(fetchUserData(token, data.id));
      })
      .catch(error => {
        dispatch({
          type: AUTH_ERROR,
          error,
        });
      });
  };
}

export function fetchUserData(token, id) {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    BaseAPI.get(Constants.API_CONTACTS_URL, id, null, token)
      .then(data => {
        dispatch(fetchUserDataSuccess(data));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
}

function setLoading(isLoading) {
  return {
    type: SET_LOADING,
    isLoading,
  };
}

function fetchUserDataSuccess(response) {
  return {
    type: FETCH_USER_DATA_SUCCESS,
    response: response,
  };
}

export function invalidateToken(token) {
  return dispatch => {
    return BaseAPI.create(Constants.API_INVALIDATE_URL, null, token)
      .then(() => {
        dispatch({
          type: INVALIDATE_TOKEN,
        });
      })
      .catch(err => {
        dispatch({
          type: INVALIDATE_TOKEN_ERROR,
        });
      });
  };
}

/** Contact Info */
export function updateContactInfoField(path, newValue) {
  return {
    type: UPDATE_CONTACT_INFO_FIELD,
    path,
    newValue,
  };
}

export function toggleMailingSameAsBilling() {
  return {
    type: TOGGLE_MAILING_SAME_AS_BILLING,
  };
}

export function onSaveContactInfo(contactInfo) {
  return async (dispatch, getState) => {
    const { profile } = getState();
    dispatch(onSaveContactInfoRequest());

    const data = buildContactInfoSaveData(contactInfo);
    try {
      const res = await BaseAPI.edit(
        Constants.API_CONTACTS_URL,
        `${profile.auth.id}/?getPriceChanges=true`,
        data,
        profile.auth.token
      );

      if (res.contact.price_changes && !!res.contact.price_changes.length) {
        dispatch(handlePriceChanges(res.contact.price_changes));
        return true;
      } else {
        dispatch(handleSaveContactInfo());
        return false;
      }
    } catch (err) {
      dispatch(onSaveContactInfoFailure(err));
      throw err;
    }
  };
}

function handleSaveContactInfo() {
  return dispatch => {
    dispatch(onSaveContactInfoSuccess());
    dispatch(toggleDisplayMessage('displaySaved', true));
  };
}

function handlePriceChanges(priceChanges) {
  return (dispatch, getState) => {
    dispatch(setPriceChanges(priceChanges));
    dispatch(toggleDisplayPriceChanges(true));
  };
}

export function handleConfirmPriceChanges() {
  return (dispatch, getState) => {
    dispatch(toggleDisplayPriceChanges(false));
    dispatch(setPriceChanges([]));
    dispatch(handleSaveContactInfo());
  };
}

function setPriceChanges(priceChanges) {
  return {
    type: SET_PRICE_CHANGES,
    priceChanges,
  };
}

function toggleDisplayPriceChanges(bool) {
  return {
    type: TOGGLE_DISPLAY_PRICE_CHANGES,
    bool,
  };
}

function buildContactInfoSaveData(contactInfo) {
  const {
    person: {
      id,
      netsuite_id,
      first_name,
      middle_name,
      last_name,
      gender,
      marital_status,
      person_type,
      birthday,
      primary_phone,
      mobile_phone,
      work_phone,
      email,
    },
    mailing_address,
    billing_address,
    changes,
  } = contactInfo;
  const date = new Date().getTime();
  const parsedMailingAddress = {
    street: mailing_address.line1,
    city: mailing_address.city,
    state: mailing_address.state,
    zipcode: mailing_address.zip,
    latitude: mailing_address.latitude || null,
    longitude: mailing_address.longitude || null,
    verifiedDate: !!mailing_address.addressVerified ? date : null,
    overrideDate: !!mailing_address.addressVerified ? null : date,
  };
  const parsedBillingAddress = {
    street: billing_address.line1,
    city: billing_address.city,
    state: billing_address.state,
    zipcode: billing_address.zip,
    latitude: billing_address.latitude || null,
    longitude: billing_address.longitude || null,
    verifiedDate: !!billing_address.addressVerified ? date : null,
    overrideDate: !!billing_address.addressVerified ? null : date,
  };
  // // LINK-728 We only want to create a SF case for changes
  // // to primary_phone, work_phone, or mailing_address
  let parsedChanges = {};
  if (changes.primary_phone) {
    parsedChanges.primary_phone = changes.primary_phone;
  }
  if (changes.work_phone) {
    parsedChanges.work_phone = changes.work_phone;
  }
  if (changes.mailing_address) {
    // LINK-763 Don't create cases for null lat/lng changes
    changes.mailing_address.latitude === null &&
      delete changes.mailing_address.latitude;
    changes.mailing_address.longitude === null &&
      delete changes.mailing_address.longitude;
    delete changes.mailing_address.addressVerified;
  }

  if (!_.isEmpty(changes.mailing_address)) {
    parsedChanges.mailing_address = { ...changes.mailing_address };
  }

  return {
    contact: {
      id: replaceNullUndefinedWithEmptyString(id),
      netsuite_id: replaceNullUndefinedWithEmptyString(netsuite_id),
      first_name: replaceNullUndefinedWithEmptyString(first_name),
      middle_name: replaceNullUndefinedWithEmptyString(middle_name),
      last_name: replaceNullUndefinedWithEmptyString(last_name),
      gender: replaceNullUndefinedWithEmptyString(gender),
      marital_status: replaceNullUndefinedWithEmptyString(marital_status),
      person_type: replaceNullUndefinedWithEmptyString(person_type),
      birthday: replaceNullUndefinedWithEmptyString(birthday),
      primary_phone: replaceNullUndefinedWithEmptyString(primary_phone),
      mobile_phone: replaceNullUndefinedWithEmptyString(mobile_phone),
      work_phone: replaceNullUndefinedWithEmptyString(work_phone),
      email: replaceNullUndefinedWithEmptyString(email),
      mailing_address: parsedMailingAddress,
      billing_address: parsedBillingAddress,
      changes: parsedChanges,
    },
  };
}

function replaceNullUndefinedWithEmptyString(value) {
  if (typeof value !== 'undefined' && value) return value;
  return '';
}

function onSaveContactInfoRequest() {
  return {
    type: ON_SAVE_CONTACT_INFO_REQUEST,
  };
}

function onSaveContactInfoSuccess() {
  return {
    type: ON_SAVE_CONTACT_INFO_SUCCESS,
  };
}

function onSaveContactInfoFailure(error) {
  return {
    type: ON_SAVE_CONTACT_INFO_FAILURE,
  };
}

export function toggleDisplayMessage(messageType, bool) {
  return {
    type: TOGGLE_DISPLAY_MESSAGE,
    messageType,
    bool,
  };
}

/** HIPAA Authorization **/

export function hipaaFetchAllData() {
  return (dispatch, getState) => {
    const { profile } = getState();
    BaseAPI.get(Constants.API_HIPAA_AUTH_URL, null, null, profile.auth.token)
      .then(data => {
        dispatch({
          type: HIPAA_SET_DATA,
          data,
        });
      })
      .catch(error => console.log(error));
  };
}

export function hipaaAddAuthorizedUser(user, cb = () => {}) {
  return (dispatch, getState) => {
    const {
      auth: { token },
    } = getState().profile;
    dispatch(setLoading(true));
    BaseAPI.create(
      Constants.API_HIPAA_AUTH_URL,
      { contact_authorization: user },
      token
    )
      .then(({ contact_authorization }) => {
        dispatch(setLoading(false));
        dispatch({
          type: HIPAA_ADD_AUTHORIZED_USER,
          user: contact_authorization,
        });
        cb(null);
      })
      .catch(() => {
        dispatch(setLoading(false));
        cb('There was an error adding this user.');
      });
  };
}

export function hipaaAddAuthorizedContactId(contact_uid, cb = () => {}) {
  return (dispatch, getState) => {
    const {
      auth: { token },
    } = getState().profile;
    dispatch(setLoading(true));
    BaseAPI.create(
      Constants.API_HIPAA_AUTH_URL,
      { contact_authorization: { contact_uid } },
      token
    )
      .then(({ contact_authorization }) => {
        dispatch(setLoading(false));
        dispatch({
          type: HIPAA_ADD_AUTHORIZED_USER,
          user: contact_authorization,
        });
        cb(null);
      })
      .catch(() => cb('There was an error adding this user.'));
  };
}

export function hipaaRemoveAuthorizedUser(
  contact_authorization_id,
  cb = () => {}
) {
  return (dispatch, getState) => {
    const {
      auth: { token },
    } = getState().profile;
    dispatch(setLoading(true));
    BaseAPI.delete(
      Constants.API_HIPAA_AUTH_URL,
      contact_authorization_id,
      token
    ).then(() => {
      dispatch({
        type: HIPAA_REMOVE_AUTHORIZED_USER,
        contact_authorization_id,
      });
      cb(null);
      dispatch(setLoading(false));
    });
  };
}

/** Paperless Statements **/

export function paperlessFetchAllData() {
  return (dispatch, getState) => {
    const {
      auth: { token },
    } = getState().profile;
    BaseAPI.get(Constants.API_PAPERLESS_URL, null, null, token)
      .then(({ paperless_statements }) => {
        dispatch({
          type: PAPERLESS_SET_DATA,
          paperless_statements,
        });
      })
      .catch(() => console.log('There was an issue getting paperless data.'));
  };
}

export function paperlessEditData(data) {
  return (dispatch, getState) => {
    const {
      auth: { token },
      paperless_statements,
    } = getState().profile;
    dispatch({
      type: PAPERLESS_EDIT_DATA,
      paperless_statements: {
        ...paperless_statements,
        ...data,
      },
    });
    BaseAPI.edit(
      Constants.API_PAPERLESS_URL,
      null,
      { paperless_statements: data },
      token
    ).catch(() => {
      dispatch({
        type: PAPERLESS_EDIT_DATA,
        paperless_statements,
      });
    });
  };
}

/** Change Password **/

export function changePassword(newPassword, error = () => {}) {
  return (dispatch, getState) => {
    const {
      auth: { token },
    } = getState().profile;
    const payload = {
      new_password: newPassword,
    };
    BaseAPI.edit(Constants.API_CHANGE_PASSWORD_URL, null, payload, token)
      .then(() => error(false))
      .catch(err => {
        if (err.response.error.includes('E0000014')) {
          error('Your current password is incorrect.');
        } else {
          error('There was an error changing your password.');
        }
      });
  };
}

/** Message Methods **/

export function getMessageMethods() {
  return (dispatch, getState) => {
    const {
      auth: { token },
    } = getState().profile;
    BaseAPI.get(Constants.API_MESSAGE_METHODS_URL, null, null, token).then(
      ({ message_methods }) =>
        dispatch({
          type: SET_MESSAGE_METHODS,
          message_methods,
        })
    );
  };
}

export function addMessageMethod(code) {
  return (dispatch, getState) => {
    const {
      auth: { token },
    } = getState().profile;
    const payload = {
      message_method: { code },
    };
    dispatch(setLoading(true));
    BaseAPI.create(Constants.API_MESSAGE_METHODS_URL, payload, token)
      .then(({ message_method }) => {
        dispatch({
          type: EDIT_MESSAGE_METHOD,
          message_method,
        });
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
}

export function deleteMessageMethod(approval_id) {
  return (dispatch, getState) => {
    const {
      auth: { token },
    } = getState().profile;
    dispatch(setLoading(true));
    BaseAPI.delete(Constants.API_MESSAGE_METHODS_URL, approval_id, token)
      .then(({ message_method }) => {
        dispatch({
          type: EDIT_MESSAGE_METHOD,
          message_method,
        });
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
}
