import { combineReducers } from 'redux';

import currentLocation from './currentLocation';
import providers from './providers';
import profile from './profile';
import view from './view';
import setPassword from './setPassword';
import modal from './modal';

export default combineReducers({
  currentLocation,
  providers,
  profile,
  view,
  setPassword,
  modal,
});
