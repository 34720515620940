import qs from 'qs';

class ProviderAPI {
  static setConfig(config) {
    ProviderAPI.config = {
      ...ProviderAPI.config,
      ...config,
    };

    return ProviderAPI.config;
  }

  static getDefaultFetchOptions() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return options;
  }

  static buildResponseError(response) {
    const error = new Error(response.status);

    error.response = response;

    return error;
  }

  static fetch(path, opts = {}) {
    const options = {
      ...ProviderAPI.getDefaultFetchOptions(),
      ...opts,
    };
    let url = `${ProviderAPI.config.url}${path}`;

    if (options.query) {
      url += `?${qs.stringify(options.query)}`;
    }

    return fetch(url, options).then(response =>
      response.json().then(
        data => {
          if (!response.ok) {
            throw ProviderAPI.buildResponseError(response);
          }

          return data;
        },
        () => {
          if (!response.ok) {
            throw ProviderAPI.buildResponseError(response);
          }

          return {};
        }
      )
    );
  }
}

ProviderAPI.config = {
  url: null,
};

export default ProviderAPI;
