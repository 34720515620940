import { CCMAddress } from 'ccm-react-components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {
  toggleMailingSameAsBilling,
  updateContactInfoField,
} from '../../../actions/profile';
import Phone from '../../../assets/img/icon-phone.png';
import SaveButton from '../SaveButton';
import _ from 'lodash';

const ContactInfo = props => {
  const [addressInvalidated, setAddressInvalidated] = useState(false);

  function renderAddress(addressType, address) {
    const basePathMap = {
      'Mailing Address': 'mailing_address',
      'Billing Address': 'billing_address',
    };
    return (
      <CCMAddress
        title={addressType}
        muleApiUrl={process.env.REACT_APP_MULE_API_URL}
        muleClientId={process.env.REACT_APP_MULE_API_CLIENT_ID}
        muleClientSecret={process.env.REACT_APP_MULE_API_CLIENT_SECRET}
        includeGeocodeData={true}
        onAddressCollected={validAddress => {
          setAddressInvalidated(false);
          return parseValidAddress(basePathMap[addressType], validAddress);
        }}
        onAddressInvalidated={() => {
          return setAddressInvalidated(true);
        }}
        style={{
          mainWidth: 'address-wrapper',
          borderRadius: 'address-border-radius',
          inputStyle: !!addressInvalidated && 'address-invalidated',
        }}
        address={address}
      />
    );
  }

  function parseValidAddress(basePath, address) {
    return Object.entries(address).forEach(entry => {
      return props.onUpdateField(`${basePath}.${entry[0]}`, entry[1]);
    });
  }

  const {
    person: {
      primary_phone,
      mobile_phone,
      work_phone,
      marital_status,
      has_spouse,
    },
    mailing_address,
    billing_address,
    mailingSameAsBilling,
    isDirty,
    isSaving,
    displaySaved,
    isValid,
    changes,
  } = props.contactInfo;
  const maritalStatusDisabled =
    !changes.marital_status && marital_status === 'Married' && has_spouse;
  return (
    <div>
      <h6>Marital Status</h6>
      <div data-for="marital-status-tooltip" data-tip style={{ width: '50%' }}>
        <select
          className={`marital-status-select ${marital_status === null &&
            'error'}`}
          onChange={e =>
            props.onUpdateField('person.marital_status', e.target.value)
          }
          disabled={maritalStatusDisabled}
        >
          <option
            selected={marital_status === null || marital_status === undefined}
          >
            Please provide your marital status...
          </option>
          <option selected={marital_status === 'Single'}>Single</option>
          <option selected={marital_status === 'Married'}>Married</option>
          <option selected={marital_status === 'Widowed'}>Widowed</option>
          <option selected={marital_status === 'Divorced'}>Divorced</option>
        </select>
      </div>
      {maritalStatusDisabled && (
        <ReactTooltip
          id="marital-status-tooltip"
          className="marital-status-tooltip"
          place="right"
          type="light"
          effect="solid"
          border={true}
          delayHide={1000}
          multiline={true}
        >
          <div className="text-center">
            <p>
              In order to update your marital status please call Member
              Services:
            </p>
            <p className="marital-tooltip-cta">
              <img className="tooltip-phone" src={Phone} alt="Phone" />
              <b>(800) 264-2562</b>
            </p>
            <p>
              <b>Monday-Friday 8am-9pm EST</b>
            </p>
          </div>
        </ReactTooltip>
      )}
      <h6>Primary Phone</h6>
      <NumberFormat
        className={primary_phone && primary_phone.length < 10 && 'error'}
        format="(###) ###-####"
        mask="_"
        value={primary_phone}
        onValueChange={value =>
          props.onUpdateField('person.primary_phone', value.value)
        }
      />
      <h6>Mobile Phone</h6>
      <NumberFormat
        className={mobile_phone && mobile_phone.length < 10 && 'error'}
        format="(###) ###-####"
        mask="_"
        value={mobile_phone}
        onValueChange={value =>
          props.onUpdateField('person.mobile_phone', value.value)
        }
      />
      <h6>Work Phone</h6>
      <NumberFormat
        className={work_phone && work_phone.length < 10 && 'error'}
        format="(###) ###-####"
        mask="_"
        value={work_phone}
        onValueChange={value =>
          props.onUpdateField('person.work_phone', value.value)
        }
      />
      {!!mailing_address.line1 && (
        <>{renderAddress('Mailing Address', mailing_address)}</>
      )}
      <input
        name="mailingSameAsBilling"
        type="checkbox"
        checked={mailingSameAsBilling}
        onChange={() => props.onToggleMailingSameAsBilling()}
      />{' '}
      Mailing Address Same as Billing Address
      {mailingSameAsBilling === false && !!billing_address.line1 && (
        <div>
          <br />
          {renderAddress('Billing Address', billing_address)}
        </div>
      )}
      {!isValid && <p className="text-danger">Required fields are missing.</p>}
      {_.isEmpty(changes) && <p>No changes to save.</p>}
      <SaveButton
        data-testid="save-contact-info-button"
        onSave={() => props.onSaveContactInfo()}
        disabled={_.isEmpty(changes) || isSaving || !isDirty || !isValid}
        displaySaved={displaySaved}
        savedMessage={'Saved!'}
        text={isSaving ? 'Saving...' : 'Save'}
      />
    </div>
  );
};

ContactInfo.propTypes = {
  contactInfo: PropTypes.shape({
    person: PropTypes.shape({
      primary_phone: PropTypes.string.isRequired,
      mobile_phone: PropTypes.string,
      work_phone: PropTypes.string,
    }),
    mailing_address: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
    }),
    billing_address: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
    }),
    mailingSameAsBilling: PropTypes.bool.isRequired,
    isDirty: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    displaySaved: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
  }),
  onUpdateField: PropTypes.func.isRequired,
  onToggleMailingSameAsBilling: PropTypes.func.isRequired,
  onSaveContactInfo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.profile.isLoading,
  contactInfo: state.profile.contactInfo,
  profile: state.profile,
});

const mapDispatchToProps = dispatch => ({
  onUpdateField: (path, newValue) =>
    dispatch(updateContactInfoField(`contactInfo.${path}`, newValue)),
  onToggleMailingSameAsBilling: () => dispatch(toggleMailingSameAsBilling()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactInfo);
