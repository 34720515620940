export const initialState = {
  isRequesting: false,
  hasFailed: false,
  coordinates: null,
};

const currentLocation = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_LOCATION':
      return {
        ...state,
        coordinates: action.coordinates,
      };

    case 'REQUEST_CURRENT_LOCATION_START':
      return {
        ...state,
        isRequesting: true,
        hasFailed: false,
        coordinates: null,
      };

    case 'REQUEST_CURRENT_LOCATION_SUCCESS':
      return {
        ...state,
        isRequesting: false,
        hasFailed: false,
        coordinates: {
          latitude: action.position.coords.latitude,
          longitude: action.position.coords.longitude,
        },
      };

    case 'REQUEST_CURRENT_LOCATION_FAILURE':
      return {
        ...state,
        isRequesting: false,
        hasFailed: true,
      };

    default:
      return state;
  }
};

export default currentLocation;
