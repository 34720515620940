import BaseAPI from '../utils/API/BaseAPI';
import Constants from '../utils/API/Constants';
import {
  GET_PASSWORD_REQUIREMENTS,
  SET_DEFAULT_PASSWORD_REQUIREMENTS,
  SET_PASSWORD_AUTHENTICATE_TOKEN,
  SET_PASSWORD_ERROR_MESSAGE,
  SET_PASSWORD_LOADING,
  SET_PASSWORD_SUCCESS,
  CLEAR_PASSWORD_ERROR_MESSAGE,
  UPDATE_NEW_PASSWORD_VALIDITY,
} from '../redux-constants';

function extractUsernameParts(username) {
  let usernamePartsArray = [];
  const removeAtSymbol = username.split('@');
  removeAtSymbol.forEach(part => {
    const splitPartsArray = [];
    // removes any portion of an email address with fewer than 4 letters after a dot
    if (part.indexOf('.') !== -1) {
      const splitParts = part.split('.');
      splitParts.forEach(element => {
        if (element.length >= 4) splitPartsArray.push(element.toLowerCase());
      });
    } else splitPartsArray.push(part.toLowerCase());
    usernamePartsArray = usernamePartsArray.concat(splitPartsArray);
  });
  return usernamePartsArray;
}

export function authenticateToken(token, type) {
  return dispatch => {
    dispatch({
      type: SET_PASSWORD_LOADING,
      isLoading: true,
    });
    const data = type === 'recover' ? { recovery_token: token } : { token };
    const url =
      type === 'recover'
        ? `${Constants.API_PASSWORDS_URL}/recovery_token`
        : `${Constants.API_LOGIN_URL}/activate`;
    return BaseAPI.create(url, data)
      .then(response => {
        dispatch({
          type: SET_PASSWORD_AUTHENTICATE_TOKEN,
          stateToken: response.state_token,
          userEmailParts:
            type === 'recover'
              ? extractUsernameParts(response.user_email)
              : null,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_PASSWORD_ERROR_MESSAGE,
          errorMessage:
            'Token is invalid.\n\nPlease delete all previous password reset emails and use only the most current.',
          newTokenNeeded: true,
        });
      });
  };
}

export function fetchPasswordRequirements() {
  return dispatch => {
    dispatch({
      type: SET_PASSWORD_LOADING,
      isLoading: true,
    });
    return BaseAPI.get(`${Constants.API_PASSWORDS_URL}/policy`)
      .then(response => {
        dispatch({
          type: GET_PASSWORD_REQUIREMENTS,
          passwordRequirements: response,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_DEFAULT_PASSWORD_REQUIREMENTS,
          errorMessage: 'Could not get password requirements.',
        });
      });
  };
}

export function setPassword(password) {
  return (dispatch, getState) => {
    const { setPassword } = getState();
    dispatch({
      type: CLEAR_PASSWORD_ERROR_MESSAGE,
    });
    dispatch({
      type: SET_PASSWORD_LOADING,
      isLoading: true,
    });
    const data = { new_password: password };
    return BaseAPI.edit(
      Constants.API_PASSWORDS_URL,
      setPassword.stateToken,
      data
    )
      .then(response => {
        if (response.status === 'SUCCESS') {
          dispatch({
            type: SET_PASSWORD_SUCCESS,
            success: true,
          });
        }
      })
      .catch(err => {
        const { response } = err;
        if (response.error && response.error.search('E0000080') !== -1) {
          dispatch({
            type: SET_PASSWORD_ERROR_MESSAGE,
            errorMessage:
              'You cannot use your current password. Please choose a new one.',
            newTokenNeeded: false,
          });
        } else {
          dispatch({
            type: SET_PASSWORD_ERROR_MESSAGE,
            errorMessage:
              'An error occurred with your request. Please try again.',
            newTokenNeeded: false,
          });
        }
      });
  };
}

export function updateNewPasswordValidity(isValid) {
  return (dispatch, getState) => {
    const {
      setPassword: { newPasswordValid },
    } = getState();
    if (isValid !== newPasswordValid) {
      dispatch({
        type: UPDATE_NEW_PASSWORD_VALIDITY,
        valid: isValid,
      });
    }
  };
}
