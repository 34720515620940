import React from 'react';
import Spinner from '../Spinner';
import ChangePassword from './ChangePassword';
import ContactInfo from './ContactInfo';
import Section from './Section';

export default ({ sectionProps, isLoading, onSaveContactInfo }) => {
  return (
    <>
      <div style={{ borderBottom: '1px solid #e1e1e1' }}>
        <Section
          title={'Personal Information'}
          sectionName={'contactInfo'}
          {...sectionProps}
        >
          <ContactInfo onSaveContactInfo={onSaveContactInfo} />
        </Section>
        <Section
          title={'Change Password'}
          sectionName={'changePassword'}
          {...sectionProps}
        >
          <ChangePassword />
        </Section>
        {/*<Section
              title={'HIPAA Authorization'}
              sectionName={'hipaaAuthorization'}
              {...sectionProps}
            >
              <Hipaa />
            </Section>*/}
      </div>
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: 'rgba(255,255,255,0.75)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};
