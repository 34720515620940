import React, { Component } from 'react';
import { connect } from 'react-redux';

import { invalidateToken } from '../../actions/profile';

import './styles.css';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAccountMenu: false,
      showMenu: false,
      accountMenuHeight: 0,
      contentHeight: 0,
      headerHeight: 0,
    };
    this.jwt = window.location.search.split('jwt=')[1];
  }
  componentDidMount() {
    this.getHeights();
  }
  componentDidUpdate() {
    this.getHeights();
  }
  getHeights() {
    setTimeout(() => {
      let stateChanged = false;
      const newState = this.state;
      const menu = document.getElementById('accountMenu');
      const content = document.getElementById('content');
      const header = document.getElementById('header');
      if (menu) {
        if (this.state.accountMenuHeight !== menu.scrollHeight) {
          newState.accountMenuHeight = menu.scrollHeight;
          stateChanged = true;
        }
      }
      if (content) {
        if (this.state.contentHeight !== content.scrollHeight) {
          newState.contentHeight = content.scrollHeight;
          stateChanged = true;
        }
      }
      if (header) {
        if (this.state.headerHeight !== header.scrollHeight) {
          newState.headerHeight = header.scrollHeight;
          stateChanged = true;
        }
      }
      if (stateChanged) this.setState(newState);
    }, 1000);
  }
  renderNavigation() {
    const {
      profile: {
        auth: { token },
        contactInfo: {
          person: { email, first_name, last_name },
        },
      },
      view: { innerWidth },
    } = this.props;
    return (
      <div
        className="inner"
        style={{
          display: innerWidth < 768 && !this.state.showMenu ? 'none' : 'block',
        }}
      >
        <ul>
          {innerWidth > 1187 && (
            <li>
              <a
                href={`${process.env.REACT_APP_WSOL_URL}/members/`}
                className="logo-link"
              >
                <img
                  src="/ccm-logo.jpg"
                  style={{ maxWidth: 240 }}
                  alt="My Christian Care"
                />
              </a>
            </li>
          )}
          {innerWidth < 768 && (
            <li className="submenu">
              <div
                className="submenuToggle"
                onClick={() =>
                  this.setState({
                    showAccountMenu: !this.state.showAccountMenu,
                  })
                }
              >
                Hi, {first_name} {last_name}
                <span
                  className={`toggleSprite ${
                    this.state.showAccountMenu ? 'open' : ''
                  }`}
                />
              </div>
              <ul
                id="accountMenu"
                style={{
                  height: this.state.showAccountMenu
                    ? this.state.accountMenuHeight
                    : 0,
                }}
              >
                <li>
                  <button
                    onClick={() =>
                      (window.location.href = `${
                        process.env.REACT_APP_WSOL_URL
                      }/members/`)
                    }
                  >
                    Member Center
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      (window.location.href = `/account/edit?jwt=${token}`)
                    }
                  >
                    Edit Profile
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      (window.location.href = `${
                        process.env.REACT_APP_WSOL_URL
                      }/our-ministry/contact-us/`)
                    }
                  >
                    Contact Us
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      this.props
                        .invalidateToken(this.jwt)
                        .then(
                          () =>
                            (window.location.href = `${
                              process.env.REACT_APP_PORTAL_URL
                            }/logout`)
                        );
                    }}
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </li>
          )}
          {innerWidth < 768 && (
            <li>
              <a href={`${process.env.REACT_APP_WSOL_URL}/members/`}>
                Member Center
              </a>
            </li>
          )}
          <li>
            <a href={`${process.env.REACT_APP_WSOL_URL}/our-ministry/`}>
              Our Ministry
            </a>
          </li>
          <li>
            <a href={`${process.env.REACT_APP_WSOL_URL}/medi-share/`}>
              Medi-Share
            </a>
          </li>
          <li>
            <a href={`${process.env.REACT_APP_WSOL_URL}/blog/`}>Blog</a>
          </li>
          <li>
            <a
              href={`${
                process.env.REACT_APP_WSOL_URL
              }/our-ministry/contact-us/`}
            >
              Contact Us
            </a>
          </li>
          {innerWidth > 1187 && email !== '' && (
            <li className="account">
              <div
                className="button"
                onClick={() =>
                  this.setState({
                    showAccountMenu: !this.state.showAccountMenu,
                  })
                }
              >
                <span className="name">
                  Hi, {first_name} {last_name}
                </span>
                <span
                  className={this.state.showAccountMenu ? 'icon flip' : 'icon'}
                />
              </div>
              <div
                id="accountMenu"
                className="submenu"
                style={{
                  height: this.state.showAccountMenu
                    ? this.state.accountMenuHeight
                    : 0,
                }}
              >
                <ul style={{ textAlign: 'right' }}>
                  <li>
                    <button
                      onClick={() =>
                        (window.location.href = `${
                          process.env.REACT_APP_WSOL_URL
                        }/members/`)
                      }
                    >
                      Member Center
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        (window.location.href = `/account/edit?jwt=${token}`)
                      }
                    >
                      Edit Profile
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        (window.location.href = `${
                          process.env.REACT_APP_WSOL_URL
                        }/our-ministry/contact-us/`)
                      }
                    >
                      Contact Us
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        this.props
                          .invalidateToken(this.jwt)
                          .then(
                            () =>
                              (window.location.href = `${
                                process.env.REACT_APP_PORTAL_URL
                              }/logout`)
                          );
                      }}
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          )}
        </ul>
        {this.state.showMenu && innerWidth < 768 && (
          <div
            className="closeMenu"
            onClick={() => this.setState({ showMenu: false })}
          >
            x
          </div>
        )}
      </div>
    );
  }
  render() {
    const navigation = this.renderNavigation();
    const {
      profile: {
        auth: { token },
        contactInfo: {
          person: { email, first_name, last_name },
        },
      },
      view: { innerWidth },
    } = this.props;
    return (
      <header id="header">
        <div
          className="main-nav"
          style={{
            height:
              innerWidth < 768
                ? this.state.headerHeight + this.state.contentHeight + 1
                : 'auto',
            display:
              innerWidth < 768 && !this.state.showMenu ? 'none' : 'block',
          }}
        >
          {navigation}
        </div>
        <div
          style={{
            opacity: 0,
            pointerEvents: 'none',
            display: innerWidth < 768 ? 'none' : 'block',
          }}
        >
          {navigation}
        </div>
        {innerWidth < 1188 && (
          <div className="header">
            <div className="inner">
              <ul>
                <li>
                  <a
                    href={`${process.env.REACT_APP_WSOL_URL}/members/`}
                    className="logo-link"
                  >
                    <img
                      src="/ccm-logo.jpg"
                      className="logo"
                      alt="My Christian Care"
                    />
                  </a>
                </li>
                {innerWidth < 768 && (
                  <li style={{ position: 'absolute', top: 0, right: 0 }}>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.setState({ showMenu: true })}
                    >
                      <img src="/icon-menu.png" alt="menu" />
                    </div>
                  </li>
                )}
                {innerWidth > 767 && email !== '' && (
                  <li className="account">
                    <div
                      className="button"
                      onClick={() =>
                        this.setState({
                          showAccountMenu: !this.state.showAccountMenu,
                        })
                      }
                    >
                      <span className="name">
                        Hi, {first_name} {last_name}
                      </span>
                      <span
                        className={
                          this.state.showAccountMenu ? 'icon flip' : 'icon'
                        }
                      />
                    </div>
                    <div
                      id="accountMenu"
                      className="submenu"
                      style={{
                        height: this.state.showAccountMenu
                          ? this.state.accountMenuHeight
                          : 0,
                      }}
                    >
                      <ul style={{ textAlign: 'right' }}>
                        <li>
                          <button
                            onClick={() =>
                              (window.location.href = `${
                                process.env.REACT_APP_WSOL_URL
                              }/members/`)
                            }
                          >
                            Member Center
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() =>
                              (window.location.href = `/account/edit?jwt=${token}`)
                            }
                          >
                            Edit Profile
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() =>
                              (window.location.href = `${
                                process.env.REACT_APP_WSOL_URL
                              }/our-ministry/contact-us/`)
                            }
                          >
                            Contact Us
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() => {
                              this.props
                                .invalidateToken(this.jwt)
                                .then(
                                  () =>
                                    (window.location.href = `${
                                      process.env.REACT_APP_PORTAL_URL
                                    }/logout`)
                                );
                            }}
                          >
                            Logout
                          </button>
                        </li>
                      </ul>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
      </header>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
  view: state.view,
});

const mapDispatchToProps = dispatch => ({
  invalidateToken: token => dispatch(invalidateToken(token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
